import React, {useContext, useEffect, useState} from 'react';
import Layout from './components/Layout';
import { Typography } from 'antd';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from './components/TabPanel';
import EditWalletModal from './components/EditWalletModal';
import TransactionInfoModal from './components/TransactionInfoModal';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import TablePagination from '@mui/material/TablePagination';
import InputLabel from '@mui/material/InputLabel';
import { useMode } from 'src/hooks/ModeContext';
import AddIcon from '@mui/icons-material/Add';
import AddWalletModal from './components/AddWalletModal'
import DeleteIcon from '@mui/icons-material/Delete';
import AddClientVaultModal from './components/AddClientVault';
import BulkCreateWalletModal from './components/BulkCreateWalletModal';
import ExternalWalletTransactionTable from './components/ExternalWalletTransactionTable';
import Snackbar from '@mui/material/Snackbar';
import DeleteWalletWarning from './components/DeleteWalletWarning'
// fetch api
import serverApi from '../api/server';
import generateSignature from '../api/apiAuth'
import ExternalWalletTable from './components/ExternalWalletTable';
// import { Table, Badge, Menu, Dropdown, Button } from 'antd';
// import {DownOutlined} from '@ant-design/icons';
// import './Page.css'
// mock data
// import {clientsMock, transactionData as transactions } from '../mockData/overviewMock'

import {useCtx} from '../App'

const { Title, Text } = Typography;

interface ClientWallet {
    AcctId: string;
    Assets?: {
        AssetPair: string;
        AssetWallet: string;
        Fee: string;
        Mode: string;
        Notes: string;
        WalletAddr: string;
    }[];
    DefaultFee: string;
    LiqProvider: string;
    Name: string;
}
interface Asset {
    AssetPair: string;
    AssetWallet: string;
    Fee: string;
    Mode: string;
    Notes: string;
    WalletAddr: string;
}
const provider:any = {
    'B2C2': 'B2C2',
    'DVCHAIN': 'DVChain'
}


function Row({mode, row, handleChangeLiquidityProvider, handleOpenEditWalletDialog, handleAddWalletModal, updateWalletList, setUpdateWalletList, handleCloseAlert, deleteWallet} : any) {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState<any[]>([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);


    const handleSearchWalletValueOnChange = (e: any) => {
        let inputValue = (e.target.value).trim()
        setSearchValue(inputValue)
        let result = row.Assets.filter((i: any) => (i.WalletAddr).toUpperCase() === inputValue.toUpperCase())
        // console.log(result)
        setSearchResult(result)
    }
    const handleClickClearSearchInput = () => {
        setSearchValue('')
    }
    // sub-table pagination
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // const deleteWallet = async(acctId: string, assetpair: string, address: string) => {
    //     try{
    //         let counter = updateWalletList;
    //         let jsonData = {
    //             unit: mode,
    //             AcctId: acctId,
    //             assetpair: assetpair,
    //             address:address,
    //         }
    //         const res = await serverApi.post(`/delFBWallet`, jsonData)
    //         console.log(res)
    //         if(res.status === 200){
    //             counter = updateWalletList + 1
    //             setUpdateWalletList(counter)
    //             handleOpenAlert()
    //         }
    //     } catch(err) {
    //         console.log(err)
    //     }
    // }


    return (
        <>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                    {<IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        //disabled={!row.Assets}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.LiqProvider? row.LiqProvider.toUpperCase() : mode === 'sdm' ? 'DVCHAIN' : 'B2C2' }
                </TableCell>
                <TableCell align="left">{row.Name}</TableCell>
                <TableCell align="center">{'axe@securedigitalmarkets.com'} | {row.notificationEmail}</TableCell>
                {/* <TableCell align="center" >
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={row.LiqProvider.toUpperCase() === 'DVCHAIN'? 'DVCHAIN' : 'B2C2'} style={{width: '200px', height: '30px'}}
                            onChange={(e) => handleChangeLiquidityProvider(e, row)}
                            renderValue={() => row.LiqProvider}
                        >
                            <MenuItem value={'DVCHAIN'}>DV Chain</MenuItem>
                            <MenuItem value={'B2C2'}>B2C2</MenuItem>
                        </Select>
                    </FormControl>
                </TableCell> */}
            </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: 55}}>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 350, marginBottom: 1 }}
                                >
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        value={searchValue}
                                        onChange={(e) => handleSearchWalletValueOnChange(e)}
                                        placeholder="Search Wallet Address"
                                        inputProps={{ 'aria-label': 'search wallet address' }}
                                        // startAdornment={<SearchIcon /> }
                                        endAdornment={searchValue? 
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size='small'
                                                    onClick={handleClickClearSearchInput}
                                                    style={{borderRadius: 0}}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </InputAdornment> : <SearchIcon />}
                                    />
                                </Paper>
                                <Button variant="outlined" size="small" sx={{height: '90%'}} endIcon={<AddIcon />} onClick={() => handleAddWalletModal(true, row)}>
                                    Bulk Create Wallet
                                </Button>
                            </div>
                            {/* <TableContainer component={Paper}> */}
                            {row.Assets ? 
                            <Table size="small" aria-label="purchases" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Text strong>Asset Pair</Text></TableCell>
                                        <TableCell><Text strong>Wallet Type</Text></TableCell>
                                        <TableCell><Text strong>Address</Text></TableCell>
                                        <TableCell><Text strong>Decription</Text></TableCell>
                                        <TableCell><Text strong>Notes</Text></TableCell>
                                        <TableCell><Text strong>Mode</Text></TableCell>
                                        <TableCell><Text strong>Threshold</Text></TableCell>
                                        <TableCell><Text strong>Fee</Text></TableCell>
                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {searchValue? 
                                        searchResult
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((asset:any, index:any) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {asset.AssetPair}
                                            </TableCell>
                                            <TableCell>{asset.AssetWallet}</TableCell>
                                            <TableCell>{asset.WalletAddr}</TableCell>
                                            <TableCell>{asset.Description}</TableCell>
                                            <TableCell>{asset.Notes && asset.Notes !== "\"\""? asset.Notes : null}</TableCell>
                                            <TableCell>{asset.Mode}</TableCell>
                                            <TableCell>{asset.Threshold? asset.Threshold : 0}</TableCell>
                                            <TableCell>{asset.Fee}</TableCell>
                                            <TableCell>
                                                <Button variant="text" onClick={() => handleOpenEditWalletDialog(row, asset)}>Edit Wallet Detail</Button>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => deleteWallet(row.AcctId, asset.AssetPair, asset.WalletAddr)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        ))
                                        : row.Assets
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((asset:any, index:any) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {asset.AssetPair}
                                            </TableCell>
                                            <TableCell>{asset.AssetWallet}</TableCell>
                                            <TableCell>{asset.WalletAddr}</TableCell>
                                            <TableCell>{asset.Description}</TableCell>
                                            <TableCell>{asset.Notes && asset.Notes !== "\"\""? asset.Notes : null}</TableCell>
                                            <TableCell>{asset.Mode}</TableCell>
                                            <TableCell>{asset.Threshold? asset.Threshold : 0}</TableCell>
                                            <TableCell>{asset.Fee}</TableCell>
                                            <TableCell>
                                                <Button variant="text" onClick={() => handleOpenEditWalletDialog(row, asset)}>Edit</Button>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => deleteWallet(row.AcctId, asset.AssetPair, asset.WalletAddr)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table> : null}
                            {/* </TableContainer> */}
                            <TablePagination
                                rowsPerPageOptions={[3, 5, 10, 25, 100]}
                                component="div"
                                count={row.Assets ? row.Assets.length : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
        </>
    );
}

function Overview() {

    //const filters = ['Destination', 'Asset', 'Source', 'Date']
    const [clientWalletData, setClientWalletData] = useState<ClientWallet[]>([]);
    const [transactionData, setTransactionData] = useState<any[]>([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [openEditWallet, setOpenEditWallet] = useState(false);
    const [currentSelectedVault, setCurrentSelectedVault] = useState({});
    const [currentSelectedWallet, setCurrentSelectedWallet] = useState<Asset>();
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();
    const [curTxIndex, setCurTxIndex] = useState();
    const [openFilter, setOpenFilter] = useState(false);
    const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
    const [curDelWallet, setCurDelWallet] = useState<any>({});
    // const [filterBy, setFilterBy] = useState();
    const [filterVault, setFilterVault] = useState('');
    const [filterAsset, setFilterAsset] = useState('');
    const [filterSource, setFilterSource] = useState('');
    const [filterDateStart, setFilterDateStart] = useState(null);
    const [filterDateEnd, setFilterDateEnd] = useState(null);
    const [loading, setLoading] = useState(false);
    // search client 
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState<ClientWallet[]>([]);
    // search fireblock tx
    const [searchTXValue, setSearchTXValue] = useState('');
    const [searchTXResult, setSearchTXResult] = useState<any[]>([]);
    const [openAddWallet, setOpenAddWallet] = useState(false);
    const [openAddClient, setOpenAddClient] = useState(false);
    const [updateWalletList, setUpdateWalletList] = useState(0);
    
    const [state, action] = useMode()
    // const [hasFilter, setHasFilter] = useState(false);
    // GET CURRENT MODE (SDM OR GREENLINE)
    const [openAlert, setOpenAlert] = useState(false)
    const handleCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenAlert(false);
    };

    // const isAxmed = process.env.REACT_APP_AXMED === 'true' ? true : false

    const axeEnv = useCtx()
    const isAxmed = axeEnv === 'Sandbox'
    
    const handleTabChange = (event: any, newValue: any) => {
        setCurrentTab(newValue);
    };
    const getClientWalletData = async(): Promise<any[]> => {
        try {
            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const query = state.mode.toUpperCase()
            const path = `/api/returnFBAccounts?unit=${query}`

            const signature = generateSignature("GET", timeStamp, path, query, null) 

            const result = await serverApi.get('/returnFBAccounts?unit=' + state.mode.toUpperCase(), {headers: {Authorization: signature}} )

            console.log("result.data", result.data)

            // console.log(result)
            let resultJSONData = result.data
            console.log(resultJSONData)
            return resultJSONData
        }
        catch (err) {
            alert(err)
            return []
        }
    }
    useEffect(() => {
        console.log('refresh');
        
        setLoading(true)
        getClientWalletData().then(res => {
            setClientWalletData(res);
            setLoading(false)
        });
    }, [updateWalletList, state.mode])
    // useEffect(() => {
    //     setTransactionData(transactions)
    // },[transactions])
    const updateLiquidityProvider = async(rowIdx: string, provider: string) => {
        try {
            const jsonData = {
                AcctId: rowIdx,
                provider: provider
            };

            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const path = 'api/updateClient' 

            const signature = generateSignature("POST", timeStamp, path, null, jsonData) 

            const res = await serverApi.post(`/updateClient`, jsonData, {headers: {Authorization: signature}})
            // console.log(res)
        }
        catch (err) {
            alert(err)
        }
    }
    const handleChangeLiquidityProvider = async(event: any, row: any) => {
        let updatedLP = event.target.value
        // console.log(row)
        let updateArr = [...clientWalletData];
        let curentRowIndex = updateArr.findIndex(i => i === row);
        const lpname : string = provider[updatedLP]
        // console.log(lpname)
        await updateLiquidityProvider(updateArr[curentRowIndex].AcctId, lpname)
        updateArr[curentRowIndex].LiqProvider = updatedLP
        setClientWalletData(updateArr)

    }
    const updateWalletDetail = async(rowIdx: string, row: any, updatedAsset: any) => {
        try {
            const jsonData = {
                AcctId: rowIdx,
                clientName: row.Name,
                address: updatedAsset.WalletAddr,
                pair: updatedAsset.AssetPair,
                mode: updatedAsset.Mode,
                fee: updatedAsset.Fee,
                notes: updatedAsset.Notes,
                ordertype: updatedAsset.OrderType,
                limitprice: updatedAsset.LimitPrice,
                notificationEmail: updatedAsset.NotificationEmail,
                threshold: updatedAsset.Threshold
            };
            console.log(" updated asset before calling wallet endpoint", updatedAsset)
            console.log("calling update wallet endopint with this json data: ", jsonData)

            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const path = '/api/updateWallet' 

            const signature = generateSignature("POST", timeStamp, path, null, jsonData) 

            const res = await serverApi.post(`/updateWallet`, jsonData, {headers: {Authorization: signature}})
            console.log(res)
        }
        catch (err) {
            alert(err)
        }
    }
    const handleWalletDetailChange = async(row: any, asset: any, updatedAsset: any) => {
        console.log("handle wallet detail change ", updatedAsset)

        // TODO: POST UPDATED INFO TO API HERE 
        let updateArr = [...clientWalletData];
        let curentRowIndex = updateArr.findIndex(i => i === row);
        let currentRow = updateArr[curentRowIndex]
        if(currentRow.Assets) {
            let assetIndex = currentRow.Assets.findIndex(i => i === asset);
            currentRow.Assets[assetIndex] = updatedAsset
            const clientWalletId = currentRow.AcctId
            console.log(updatedAsset)
            let res = await updateWalletDetail(clientWalletId, currentRow, updatedAsset)
            // // console.log(res)
            setClientWalletData(updateArr)
            setOpenEditWallet(false)
            setCurrentSelectedVault({})
            setCurrentSelectedWallet(undefined)
        }
    }
    const onCancelWalletDetailChange = (row: any, asset: any) => {
        setOpenEditWallet(false)
        setCurrentSelectedVault({})
        setCurrentSelectedWallet(undefined)
    }
    const handleOpenEditWalletDialog = (row: any, asset: any) => {
        // console.log(row)
        // console.log(asset)
        setCurrentSelectedVault(row)
        setCurrentSelectedWallet(asset)
        setOpenEditWallet(true)
    }
    // Transaction Info modal
    const handleOpenTxInfoDialog = (row: any, index: any) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(undefined)
    }
    // filters for fireblock transaction table
    const getFireblockTransactionData = async() : Promise<any[]> => {
        try {
            let resultJSONData;
            if(state.mode === 'sdm') {
                console.log(state.mode)
            }

            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const query = state.mode.toUpperCase()
            const path = `/api/getTransactions?unit=${query}` 

            const signature = generateSignature("GET", timeStamp, path, query, null) 

            const result = await serverApi.get('/getTransactions?unit=' + query, {headers: {Authorization: signature}})

            console.log(result)
            resultJSONData = result.data
            return resultJSONData
        }
        catch (err) {
            alert(err)
            return []
        }
    }
    useEffect(() => {
        //Filter options updated so apply all filters here
        //let result = transactions
        setLoading(true)
        getFireblockTransactionData().then(res => {
            let result: any[] = res
            if(result) {
                if(searchTXValue) {
                    result = result.filter((i: any) => (i.destination.name).toUpperCase().includes(searchTXValue.toUpperCase()))
                }
                // if(filterVault) {
                //     result = filterWithVault(result, filterVault);
                // }
                if(filterAsset) {
                    result = filterWithAsset(result, filterAsset);
                }
                if(filterSource) {
                    result = filterWithSource(result, filterSource)
                }
                if(filterDateStart && filterDateEnd) {
                    result = filterWithDateRange(result)
                }
                setTransactionData(result);
            }
            setLoading(false)
        });
    }, [searchTXValue, filterAsset, filterSource,filterDateStart,filterDateEnd, state.mode]);

    const openFilterMenu = () => {
        setOpenFilter(true)
    }
    const closeFilterMenu = () => {
        setOpenFilter(false)
    }
    // const filterWithVault = (arr: any[], vault: string) => {
    //     let result = arr.filter(item => item.destination.name === vault);
    //     return result
    // }
    // const handleVaultFilterChange = (event: any) => {
    //     setFilterVault(event.target.value);
    // }
    const filterWithAsset = (arr: any[], assetName: string) => {
        return arr.filter(item => item.assetId === assetName);
    }
    const handleAssetFilterChange = (event:any) => {
        setFilterAsset(event.target.value)
    }
    const filterWithSource = (arr: any[], source: string) => {
        return arr.filter(item => item.source.name === source);
    }
    const handleSourceFilterChange = (event: any) => {
        setFilterSource(event.target.value)
    }
    const filterWithDateRange = (arr: any[]) => {
        return filterDateStart && filterDateEnd? arr.filter(item => moment(item.lastUpdated).isSameOrAfter(filterDateStart, 'day') && moment(item.lastUpdated).isSameOrBefore(filterDateEnd)) : arr;
    }
    // const filterWithStartDate = (arr:any[]) => {
    //     return filterDateStart? arr.filter(item => new Date(item.lastUpdated) >= new Date(filterDateStart)) : arr;
    // }
    // const filterWithEndDate = (arr: any[]) => {
    //     return filterDateStart ? arr.filter(item => new Date(item.lastUpdated) <= new Date(filterDateStart)) : arr;
    // }
    const clearFilters = () => {
        setFilterVault('');
        setFilterAsset('')
        setFilterSource('')
        setFilterDateStart(null)
        setFilterDateEnd(null)
        // setTransactionData(transactions)
    }
    //search client name
    const handleSearchWalletValueOnChange = (e: any) => {
        let inputValue = (e.target.value).trim()
        setSearchValue(inputValue)
        let result = clientWalletData.filter((i: any) => (i.Name).toUpperCase().includes(inputValue.toUpperCase()))
        // console.log(result)
        setSearchResult(result)
    }
    const handleClickClearSearchInput = () => {
        setSearchValue('')
    }
    const handleSearchTXclient = (e:any) => {
        let inputValue = (e.target.value).trim()
        setSearchTXValue(inputValue)
        // let 
        // console.log(result)
        // setSearchTXResult(result)
    }
    const handleClickClearTransactionSearchInput = () => {
        setSearchTXValue('')
    }
    const handleAddWalletModal = (state: boolean, row: any) => {
        setCurrentSelectedVault(row)
        setOpenAddWallet(state)
    }
    const handleAddNewClientVault = () => {
        setOpenAddClient(true)
    }
    const handleOpenDeleteSuccessAlert = () => {
        setOpenAlert(true)
    }
    const openDeleteFireBlocksWalletWarnning = (acctId: string, assetpair: string, address: string) => {
        setCurDelWallet({acctId: acctId, assetpair:assetpair, address: address})
        setOpenDeleteWarning(true)
    }
    const closeDeleteFireBlocksWalletWarnning = () => {
        setCurDelWallet({})
        setOpenDeleteWarning(false)
    }
    const deleteFireBlocksWallet = async(acctId: string, assetpair: string, address: string) => {
        try{
            let counter = updateWalletList;
            let jsonData = {
                unit: state.mode,
                AcctId: curDelWallet.acctId,
                assetpair: curDelWallet.assetpair,
                address: curDelWallet.address,
            }
            console.log(jsonData)

            // generate sig

            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const path = '/api/delFBWallet' 

            const signature = generateSignature("POST", timeStamp, path, null, jsonData) 

            const res = await serverApi.post(`/delFBWallet`, jsonData, {headers: {Authorization: signature}})

            console.log(res)
            if(res.status === 200){
                counter = updateWalletList + 1
                setUpdateWalletList(counter)
                handleOpenDeleteSuccessAlert()
                closeDeleteFireBlocksWalletWarnning()
            }
        } catch(err) {
            console.log(err)
        }
    }
    return (
        <Layout>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentTab} onChange={handleTabChange}>
                        {isAxmed? null : <Tab label="Clients and Wallets" />}
                        <Tab label="External Wallets" />
                        {isAxmed? null :<Tab label="Fireblocks Transactions" />}
                        <Tab label= "External Wallet Transactions"/>
                    </Tabs>
                </Box>
                {isAxmed? null : <TabPanel value={currentTab} index={isAxmed? null : 0}>
                    <div className='clientsWalletContainer'>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 350, marginBottom: 1, marginRight: 0 }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    value={searchValue}
                                    onChange={(e) => handleSearchWalletValueOnChange(e)}
                                    placeholder="Search Client Name"
                                    inputProps={{ 'aria-label': 'search client name' }}
                                    endAdornment={searchValue? 
                                        <InputAdornment position="end">
                                            <IconButton
                                                size='small'
                                                onClick={handleClickClearSearchInput}
                                                style={{borderRadius: 0}}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment> : <SearchIcon />}
                                    />
                            </Paper>
                            <Button variant="outlined" sx={{height: '90%'}} size="small" endIcon={<AddIcon />} onClick={() => handleAddNewClientVault()}>
                                Create Client
                            </Button>
                            {/* <Title level={3}>Clients and Wallets</Title> */}
                            
                        </div>
                        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                            {loading ? <LinearProgress color="success"/> : null}
                            <Table stickyHeader aria-label="collapsible table" size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell><Title level={3}>Liquidity Provider</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Client Vault</Title></TableCell>
                                        <TableCell align="center"><Title level={3}>Email</Title></TableCell>
                                        {/* <TableCell align="center"><Title level={3}>Action</Title></TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {searchValue? 
                                    searchResult.map((client) => (
                                        <Row 
                                            mode={state.mode}
                                            key={client.AcctId} row={client} 
                                            handleChangeLiquidityProvider={handleChangeLiquidityProvider} 
                                            handleAddWalletModal={handleAddWalletModal}
                                            // handleWalletDetailChange={handleWalletDetailChange}
                                            handleOpenEditWalletDialog={handleOpenEditWalletDialog}
                                            updateWalletList={updateWalletList}
                                            setUpdateWalletList={setUpdateWalletList}
                                            handleCloseAlert={handleCloseAlert}
                                            deleteWallet={openDeleteFireBlocksWalletWarnning}
                                            //handleOpenAlert={() => setOpenAlert(true)}
                                        />
                                    ))
                                    :clientWalletData.map((client) => (
                                        <Row 
                                            mode={state.mode}
                                            key={client.AcctId} row={client} 
                                            handleChangeLiquidityProvider={handleChangeLiquidityProvider} 
                                            handleAddWalletModal={handleAddWalletModal}
                                            // handleWalletDetailChange={handleWalletDetailChange}
                                            handleOpenEditWalletDialog={handleOpenEditWalletDialog}
                                            updateWalletList={updateWalletList}
                                            setUpdateWalletList={setUpdateWalletList}
                                            handleCloseAlert={handleCloseAlert}
                                            deleteWallet={openDeleteFireBlocksWalletWarnning}
                                            //handleOpenAlert={() => setOpenAlert(true)}
                                        />
                                    )) }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </TabPanel>}
                <TabPanel value={currentTab} index={isAxmed? 0: 1}>
                    <ExternalWalletTable />
                </TabPanel>
                {isAxmed? null : <TabPanel value={currentTab} index={isAxmed? null :2}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Paper
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 200, height: 36 ,marginBottom: 1, marginRight: 5 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={searchTXValue}
                                onChange={(e) => handleSearchTXclient(e)}
                                placeholder="Search Client"
                                inputProps={{ 'aria-label': 'search client' }}
                                // startAdornment={<SearchIcon /> }
                                endAdornment={searchTXValue? 
                                <InputAdornment position="end">
                                <IconButton
                                    size='small'
                                    onClick={handleClickClearTransactionSearchInput}
                                    style={{borderRadius: 0}}
                                >
                                    <CloseIcon />
                                </IconButton>
                                </InputAdornment> : <SearchIcon />}
                            />
                        </Paper>
                        {openFilter? 
                            <Paper elevation={2} sx={{display: 'inline-flex', flexDirection: 'row', marginBottom: 5, minHeight: 50, padding: 2, paddingX: 3, paddingY: 1.5}}>
                                {/* <Text italic>filter</Text> */}
                                {/* <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <Text type="secondary">Client:</Text>
                                    <FormControl sx={{ minWidth: 120, marginRight: 3, marginTop: 1 }} size="small">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterVault}
                                            // style={{height: 35}}
                                            onChange={handleVaultFilterChange}
                                        >
                                            {transactionData && Array.from(new Set(transactionData.map(tag => tag.destination.name))).map(item => 
                                                <MenuItem key={item} value={item}>{item}</MenuItem>)}
                                        </Select>
                                    </FormControl> 
                                </div> */}
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {/* <Text type="secondary">Asset:</Text> */}
                                    <FormControl sx={{ minWidth: 120, marginRight: 3, marginTop: 1 }} size="small">
                                        <InputLabel id="demo-select-small">Asset</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterAsset}
                                            label="Asset"
                                            onChange={handleAssetFilterChange}
                                        >
                                            {transactionData && Array.from(new Set(transactionData.map(tag => tag.assetId))).map(item => 
                                                <MenuItem key={item} value={item}>{item}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {/* <Text type="secondary">Source:</Text> */}
                                    <FormControl sx={{ minWidth: 120, marginRight: 3, marginTop: 1 }} size="small">
                                        <InputLabel id="demo-select-small">Source</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterSource}
                                            label="Source"
                                            onChange={handleSourceFilterChange}
                                        >
                                            {transactionData && Array.from(new Set(transactionData.map(tag => tag.source.name))).map(item => 
                                                <MenuItem key={item} value={item}>{item}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', marginTop: 8}}>
                                    {/* <Text type="secondary" style={{marginBottom: 8}}>Date:</Text> */}
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="From"
                                            value={filterDateStart}
                                            onChange={(newValue: any) => {
                                                setFilterDateStart(newValue);
                                            }}
                                            maxDate={filterDateEnd ? filterDateEnd : null}
                                            renderInput={(params) => <TextField {...params} size="small"/>}
                                        />
                                    </LocalizationProvider>
                                    <span style={{margin: 5, textAlign: 'center'}}></span>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="To"
                                            value={filterDateEnd}
                                            onChange={(newValue: any) => {
                                                setFilterDateEnd(newValue);
                                            }}
                                            minDate={filterDateStart ? filterDateStart : null}
                                            renderInput={(params) => <TextField {...params} size="small"/>}
                                        />
                                    </LocalizationProvider>
                                </div>
                                {/* <Text italic>Sorting</Text> */}
                                <Button sx={{marginLeft: 5, height: 30, alignSelf: 'center'}} variant="text" size="small" onClick={() => clearFilters()} disabled={!(filterVault || filterAsset || filterSource || filterDateStart || filterDateEnd)}>clear filter</Button>
                                <IconButton
                                    aria-label="expand row"
                                    // size="small"
                                    sx={{marginLeft: 5, height: 40, alignSelf: 'start'}}
                                    onClick={() => closeFilterMenu()}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Paper> : <Button variant="text" size="small" onClick={() => openFilterMenu()}>open filter</Button>}
                        </div>
                        <div className='fireblockTransactionContainer'>
                            <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
                                {loading ? <LinearProgress color="success"/> : null}
                                <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Title level={3}>Date</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Client</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>TX Type</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Asset</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Amount</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Amount USD</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Source</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Destination Type</Title></TableCell>
                                        <TableCell align="left"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {transactionData && transactionData
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                                        <TableCell>{moment(row.lastUpdated).format('L')}</TableCell>
                                        <TableCell align='left'>{row.destination.name}</TableCell>
                                        <TableCell align='left'>{row.operation}</TableCell>
                                        <TableCell align='left'>{row.assetId}</TableCell>
                                        <TableCell align='left'>{row.amount}</TableCell>
                                        <TableCell align='left'>{row.amountUSD}</TableCell>
                                        <TableCell align='left'>{row.source.name}</TableCell>
                                        <TableCell align='left'>{row.destination.type}</TableCell>
                                        <TableCell align='left'>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleOpenTxInfoDialog(row, index)}
                                        >
                                            <InfoIcon color="success"/>
                                        </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    )
                                )}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </TabPanel>}
                <TabPanel value={currentTab} index={isAxmed? 1 : 3}>
                    <ExternalWalletTransactionTable />
                </TabPanel>
            </Box>
            <EditWalletModal 
                openDialog={openEditWallet} 
                closeDialog={() => setOpenEditWallet(false)} 
                row={currentSelectedVault} 
                asset={currentSelectedWallet} 
                onHandleWalletDetailChange={handleWalletDetailChange}
                onCancelWalletDetailChange={onCancelWalletDetailChange}
            />
            <AddClientVaultModal 
                mode={state.mode}
                openDialog={openAddClient} 
                closeDialog={() => setOpenAddClient(false)} 
                row={currentSelectedVault} 
                updateWalletList={updateWalletList}
                setUpdateWalletList={setUpdateWalletList}
            />
            <BulkCreateWalletModal 
                openDialog={openAddWallet} 
                closeDialog={() => setOpenAddWallet(false)} 
                row={currentSelectedVault} 
                updateWalletList={updateWalletList}
                setUpdateWalletList={setUpdateWalletList}
                mode={state.mode}
                // asset={currentSelectedWallet} 
                // onHandleWalletDetailChange={handleWalletDetailChange}
                // onCancelWalletDetailChange={() => setOpenAddWallet(false)}
            />
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
            />
            <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                message="Wallet deleted successfully"
                action={(
                    <>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseAlert}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                )}
            />
            <DeleteWalletWarning 
                open={openDeleteWarning}
                closeDialog={closeDeleteFireBlocksWalletWarnning}
                confirmDialog={deleteFireBlocksWallet}
            />
        </Layout>
    );
}

export default Overview;
