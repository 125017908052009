import React,
 {Children,
  createContext, 
  ReactNode, 
  useEffect,
  useReducer, 
  useState, 
  Dispatch, 
  PropsWithChildren, 
  SetStateAction,  
  useContext
} from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import { createBrowserHistory } from "history";
// pages
import Overview from './pages/Overview';
import Trades from './pages/Trades';
import Blockchain from './pages/Blockchain';
import Audit from './pages/Audit';
import SenderNames from './pages/SenderNames';
import jwt_decode from "jwt-decode";
import './App.css';
import PermissionErrorPage from './pages/components/PermissionErrorPage'
import { useAuth0, User, withAuthenticationRequired } from '@auth0/auth0-react';

import moment from 'moment';
import serverApi from './api/server';
import generateSignature from './api/apiAuth'


// ant components
const USER_GROUP = process.env.REACT_APP_AUTH0_USER_GROUP
const CURRENT_DOMAIN = process.env.REACT_APP_DOMAIN

const createCtx = <AxeEnv extends {} | null> () => {
  const ctx = createContext<AxeEnv | undefined>(undefined);
  function useCtx() {
    const c = useContext(ctx);
    if (c === undefined)
      throw new Error("useCtx must be inside a Provider with a value");
    return c;
  }
  return [useCtx, ctx.Provider] as const; 
}

export const [useCtx, SettingProvider] = createCtx<string>();

function App(): JSX.Element {
  const [isUserAllowed, setIsUserAllowed] = useState(false)
  console.log(process.env.NODE_ENV)
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  useEffect(() => {
    if (user) {
      console.log(user)
      getToken(user)
    }
  },[user])

  const [axeEnv, setAxeEnv] = useState('')

  useEffect(() => {
    const getEnv = async () => {
      const env = await getAxeEnv()
      setAxeEnv(env!.data)
    }
    getEnv()
  }, []) 

  const getAxeEnv = async () => {
    const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
    const path = '/api/getEnv' 

    const signature = generateSignature("GET", timeStamp, path, null, null) 

    try {
      const result = await serverApi.get('getEnv', {headers: {Authorization: signature}})
      return result
    } catch(err) {
      console.log("error while trying to get axe env", err)
    }
  }

  const getToken = async (curUser: User) => {
    const token = await getAccessTokenSilently({
      detailedResponse: true,
      // audience: AUDIENCE,
    })
    console.log(token)
    const data = jwt_decode(token.access_token) as any
    console.log(data)
    // console.log(CURRENT_DOMAIN)
    console.log(curUser['user_groups/roles'])
    checkIsUserAllowed(curUser['user_groups/roles'])
    // setToken(token)
  }
  const checkIsUserAllowed = (userPermission: any[]) => {
    console.log(userPermission)
    if(userPermission.includes(USER_GROUP)){
      setIsUserAllowed(true)
    }
  }
  if(!isAuthenticated && !isLoading){
    return (
      <>
        {loginWithRedirect()}
      </>
    )
    
    
  }
  else if(isLoading && !isAuthenticated) {
    return (
      <>
        <div>loading ...</div>
      </>
    )
  }
  else {
    return (
      isUserAllowed?
        <SettingProvider value={axeEnv}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Overview />}/>
              <Route path="/trades" element={<Trades />}/>
              <Route path="/blockchain" element={<Blockchain />}/>
              <Route path="/audit" element={<Audit />}/>
              <Route path="/sendernames" element={<SenderNames />}/>
            </Routes>
          </BrowserRouter>
        </SettingProvider>
      : <PermissionErrorPage />
    );
}}
  



export default App;
